import React, { Component } from "react"
import { Link } from "gatsby"
import axios from 'axios'

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageContent from "../components/page_content"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import "../components/broker-styles.css"
import LeftArrow from '../images/left_arrow.png'
import Calendar4Step from '../components/calendar_4step'
import Calendar5Step from '../components/calendar_5step'
import Calendar6Step from '../components/calendar_6step'

//const BrokerCalendar = props => {
class BrokerCalendar extends Component {
    constructor(props) {
        super(props);

        //console.log(props);
        var defaultSteps = [];
        var calType = '';
        props.data.allStrapiCalendarstep.nodes.forEach(function(element) {
            var step_data = {
                key: 'calstep'+element.id,
                week: element.week,
                step_number: element.step_number,
                step_text: element.step_text,
                days_out: element.days_out,
                completed: false,
            }
            defaultSteps.push(step_data);
            calType = element.type;
        });

        this.state = {
            loading: false,
            error: false,

            steps: defaultSteps,
            calendar_type: calType
        }
    }

    componentDidMount() {
        console.log(this.props);
        this.fetchCompletedSteps()
      }

    fetchCompletedSteps = () => {
        var broker_slug = this.props.pageContext.broker_slug;
        console.log('fetching steps for '+broker_slug);
        var that = this;
        axios({
            url: 'https://boss.tryelevate.com:8337/completedsteps?broker_slug='+broker_slug,
            method: 'get',
          })
        .then(steps => {
            //console.log("Received Steps:");
            steps.data.forEach(function(complete_step){
                //console.log(complete_step);
                that.markStepCompleted(complete_step.week, complete_step.step_number, complete_step.id);
            });

        })
        //.catch(error => {
        //    console.log('what error?');
        //    this.setState({ loading: false, error })
        //})
    }

    markStepCompleted(week, step_number, step_id){
        //console.log("Marking complete for week "+week+", step "+step_number);

        var these_steps = this.state.steps;
        these_steps.forEach(function(step, index){
            //console.log('Step:');
            //console.log(step);
            if(step.step_number === step_number && step.week === week){
                these_steps[index].completed = true; 
                these_steps[index].completed_id = step_id;
            }
        });

        this.setState({steps: these_steps});
    }



    render() {
        var parentPage = this.props.pageContext.broker_slug;
        var calComponent = <Calendar4Step broker_slug={this.props.pageContext.broker_slug} steps={this.state.steps}/>;
        if(this.props.pageContext.calendar_type === 'Five Step'){
            calComponent = <Calendar5Step broker_slug={this.props.pageContext.broker_slug} steps={this.state.steps}/>;
        }
        if(this.props.pageContext.calendar_type === 'Six Step'){
            calComponent = <Calendar6Step broker_slug={this.props.pageContext.broker_slug} steps={this.state.steps}/>;
        }
        //console.log(this.state);
        return (
            <Layout  logo={this.props.pageContext.broker_logo}>
            <SEO title="Broker" />
            <PageContent>
            <Container>
                {calComponent}
                <Row>
                    <Col>
                        <div style={{textAlign:"center",marginTop:"30px"}}>
                            <Link to={parentPage} style={{textDecoration:"none"}}>
                            <h4>
                            <img src={LeftArrow} alt="left arrow" style={{width:"50px",marginBottom:"0px",marginRight:"12px"}} />
                                Back</h4>
                        </Link>
                        </div>
                    </Col>
                </Row>
            </Container>
            </PageContent>
        </Layout>
        )
    }
}

export default BrokerCalendar

export const query = graphql`
  query BrokerCalendar($calendar_type: String) {
    allStrapiCalendarstep(filter: {type: {eq: $calendar_type}}, sort: {fields: week, order: ASC}) {
        nodes {
          id
          step_number
          step_text
          type
          week
          days_out
        }
      }
  }
`;