/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React,{Component} from "react"
import axios from 'axios'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Step from '../components/step'
import RightArrow from '../images/right_arrow.png'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Modal from 'react-bootstrap/Modal'
import Complete from '../components/complete_alert'
import CheckIcon from '../images/form_icon.png'

class Calendar6Step extends Component {
    constructor(props) {
        super(props);
        //console.log('constructor');
        //console.log(props);

        var week1_steps = [];
        var week2_steps = [];
        var week3_steps = [];
        var week4_steps = [];
        var default_key = 1;

        props.steps.forEach(function(step){
            if(step.week === 1){
                week1_steps.push(step);
            }
            if(step.week === 2){
                week2_steps.push(step);
            }
            if(step.week === 3){
                week3_steps.push(step);
            }
            if(step.week === 4){
                week4_steps.push(step);
            }
        })

        this.state = {
            week1_steps: week1_steps,
            week2_steps: week2_steps,
            week3_steps: week3_steps,
            week4_steps: week4_steps,
            show_week1: false,
            show_week2: false,
            show_week3: false,
            show_week4: false,
            default_key: default_key,
        }

        this.handleChange = this.handleChange.bind(this);
    }



    handleChange(week, step_number, step_id) {
        var isChecked = true;
        var these_steps = this.state['week'+week+'_steps'];
        var state_name = 'week'+week+'_steps';

        var complete_count = 0;

        these_steps.forEach(function(step, index){
            console.log(step);
            if(step.step_number === step_number){
                if(step.completed === false){
                    isChecked = true;
                    these_steps[index].completed = true;
                    complete_count = complete_count + 1;
                }
                else{
                    isChecked = false
                    these_steps[index].completed = false;
                }
            }
            else{
                if(step.completed){
                    complete_count = complete_count + 1;
                }
            }
        });

        var toUpdate = {};
        toUpdate[state_name] = these_steps;
        if(complete_count === these_steps.length){
            toUpdate['show_week'+week] = true;
        }
        
        this.setState(toUpdate);

        const broker_slug = this.props.broker_slug;

        console.log("week "+week+", step_num "+step_number+", checked "+isChecked);
        
        if(isChecked){
            axios({
                url: 'https://boss.tryelevate.com:8337/completedsteps',
                method: 'post',
                data: {
                    week: week,
                    step_number: step_number,
                    broker_slug: broker_slug
                }
                })
            .then(steps => {
                console.log(steps);
            })
            .catch(error => {
                this.setState({ loading: false, error })
            })
        }
        else{
            axios({
                url: 'https://boss.tryelevate.com:8337/completedsteps/'+step_id,
                method: 'delete',
                })
            .then(steps => {
                console.log(steps);
            })
            .catch(error => {
                this.setState({ loading: false, error })
            })
        }
        
    }

    handleWeek1Close = () => this.setState({show_week1: false});
    handleWeek2Close = () => this.setState({show_week2: false});
    handleWeek3Close = () => this.setState({show_week3: false});
    handleWeek4Close = () => this.setState({show_week4: false});

    render() {
        var default_key = 0;
        var week1_complete = 0;
        var week2_complete = 0;
        var week3_complete = 0;
        var week4_complete = 0;
        if(this.state.week1_steps.length === this.state.week1_steps.filter(function(x){return x.completed === true}).length){
            default_key = 1;
            week1_complete = 1;
        }
        if(this.state.week2_steps.length === this.state.week2_steps.filter(function(x){return x.completed === true}).length){
            default_key = 2;
            week2_complete = 1;
        }
        if(this.state.week3_steps.length === this.state.week3_steps.filter(function(x){return x.completed === true}).length){
            default_key = 3;
            week3_complete = 1;
        }
        if(this.state.week4_steps.length === this.state.week4_steps.filter(function(x){return x.completed === true}).length){
            default_key = 3;
            week4_complete = 1;
        }

        console.log("Default Key: "+default_key);
        //var key_string = default_key.toString();
        return (
        <Row>
            <Col>
                <Accordion defaultActiveKey="0">
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                    {week1_complete === 1 && (
                        <div style={{float:"right"}}><Complete /></div>
                    )}
                    <h5><img src={RightArrow} alt="right arrow icon" style={{width:"50px",marginBottom:"0px",marginRight:"12px"}} /> <span style={{color:"#1e1749"}}>Step 1</span>  |  Connections</h5>
                    
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <div style={{marginBottom:"20px"}}>
                        Your 1st week is all about connecting with your Elevate Success Team, compiling necessary collateral, and getting synced up inside of your Elevate CRM.  Go ahead...celebrate each success.
                        </div>
                        <div>
                            <ul style={{listStyleType: "none"}}>
                                {this.state.week1_steps.map((item) => (
                                        <li key={item.key}><Step name={item.key} checked={item.completed} onChange={() => this.handleChange(item.week, item.step_number, item.completed_id)} /> {item.step_text}</li>
                                ))}
                            </ul>
                        </div>
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="1">
                    {week2_complete === 1 && (
                        <div style={{float:"right"}}><Complete /></div>
                    )}
                    <h5><img src={RightArrow} alt="right arrow icon" style={{width:"50px",marginBottom:"0px",marginRight:"12px"}} /> <span style={{color:"#1e1749"}}>Step 2</span>  |  Going Live</h5>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                    <Card.Body>
                        <div style={{marginBottom:"20px"}}>
                        Ok, this next part is all on us.  The Elevate team is busy building out your IDX website and preparing to unveil it to you.  Once we do, let’s do some more celebrating together!
                        </div>
                        <div>
                            <ul style={{listStyleType: "none"}}>
                                {this.state.week2_steps.map((item) => (
                                        <li key={item.key}><Step name={item.key} checked={item.completed} onChange={() => this.handleChange(item.week, item.step_number, item.completed_id)} /> {item.step_text}</li>
                                ))}
                            </ul>
                        </div>
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="2">
                    {week3_complete === 1 && (
                        <div style={{float:"right"}}><Complete /></div>
                    )}
                    <h5><img src={RightArrow} alt="right arrow icon" style={{width:"50px",marginBottom:"0px",marginRight:"12px"}} /> <span style={{color:"#1e1749"}}>Step 3</span>  |  Training</h5>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                    <Card.Body>
                        <div style={{marginBottom:"20px"}}>
                        Mastering Elevate technology is easy...thanks to your amazing implementation team, backed by fanatical support and an account manager dedicated to your success.
                        </div>
                        <div>
                            <ul style={{listStyleType: "none"}}>
                                {this.state.week3_steps.map((item) => (
                                        <li key={item.key}><Step name={item.key} checked={item.completed} onChange={() => this.handleChange(item.week, item.step_number, item.completed_id)} /> {item.step_text}</li>
                                ))}
                            </ul>
                        </div>
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="3">
                    {week4_complete === 1 && (
                        <div style={{float:"right"}}><Complete /></div>
                    )}
                    <h5><img src={RightArrow} alt="right arrow icon" style={{width:"50px",marginBottom:"0px",marginRight:"12px"}} /> <span style={{color:"#1e1749"}}>Step 4</span>  |  Take-off!</h5>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="3">
                    <Card.Body>
                        <div>
                        7 Days out:
                        </div>
                        <div>
                            <ul style={{listStyleType: "none"}}>
                                {this.state.week4_steps.map(function(item){
                                    if(item.days_out === 7){
                                        return <li key={item.key}><Step name={item.key} checked={item.completed} onChange={() => this.handleChange(item.week, item.step_number, item.completed_id)} /> {item.step_text}</li>;
                                    }
                                    return '';
                                }, this)}
                            </ul>
                        </div>
                        <div>
                        3 Days out:
                        </div>
                        <div>
                            <ul style={{listStyleType: "none"}}>
                            {this.state.week4_steps.map(function(item){
                                    if(item.days_out === 3){
                                        return <li key={item.key}><Step name={item.key} checked={item.completed} onChange={() => this.handleChange(item.week, item.step_number, item.completed_id)} /> {item.step_text}</li>;
                                    }
                                    return '';
                                },this)}
                            </ul>
                        </div>
                        <div>
                        1 Day out:
                        </div>
                        <div>
                            <ul style={{listStyleType: "none"}}>
                            {this.state.week4_steps.map(function(item){
                                    if(item.days_out === 1){
                                        return <li key={item.key}><Step name={item.key} checked={item.completed} onChange={() => this.handleChange(item.week, item.step_number, item.completed_id)} /> {item.step_text}</li>;
                                    }
                                    return '';
                                },this)}
                            </ul>
                        </div>
                        <div>
                        The BIG day:
                        </div>
                        <div>
                            <ul style={{listStyleType: "none"}}>
                            {this.state.week4_steps.map(function(item){
                                    if(item.days_out === 0){
                                        return <li key={item.key}><Step name={item.key} checked={item.completed} onChange={() => this.handleChange(item.week, item.step_number, item.completed_id)} /> {item.step_text}</li>;
                                    }
                                    return '';
                                },this)}
                            </ul>
                        </div>
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                </Accordion>
            </Col>
            <Modal show={this.state.show_week1} onHide={this.handleWeek1Close} style={{marginTop: "40px"}}>
                <Modal.Header style={{backgroundColor:"#5bbfb717"}}>
                <Modal.Title className="modalTitle"><img src={CheckIcon} alt="check icon" style={{width:"50px",marginBottom:"0px",marginRight:"12px"}} /> Nice work!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{textAlign:"center"}}>Can you feel that fabulous connection? <br/><br/>We can! Now...onto the next set of steps.</div>
                </Modal.Body>
            </Modal>
            <Modal show={this.state.show_week2} onHide={this.handleWeek2Close} style={{marginTop: "40px"}}>
                <Modal.Header style={{backgroundColor:"#5bbfb717"}}>
                <Modal.Title className="modalTitle"><img src={CheckIcon} alt="check icon" style={{width:"50px",marginBottom:"0px",marginRight:"12px"}} /> Woo hoo!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{textAlign:"center"}}>Your mobile-friendly, IDX website is LIVE and ready to start showcasing you and your business.<br/><br/>Next up...learning how to manage it, and all of your soon to be captured leads.</div>
                </Modal.Body>
            </Modal>
            <Modal show={this.state.show_week3} onHide={this.handleWeek3Close} style={{marginTop: "40px"}}>
                <Modal.Header style={{backgroundColor:"#5bbfb717"}}>
                <Modal.Title className="modalTitle"><img src={CheckIcon} alt="check icon" style={{width:"50px",marginBottom:"0px",marginRight:"12px"}} /> Alright, alright, alright!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{textAlign:"center"}}>The final countdown is underway to your OFFICIAL OFFICE LAUNCH!<br/><br/> Can’t you just feel the energy building?  Let’s prepare for take-off...</div>
                </Modal.Body>
            </Modal>
            <Modal show={this.state.show_week4} onHide={this.handleWeek4Close} style={{marginTop: "40px"}}>
                <Modal.Header style={{backgroundColor:"#5bbfb717"}}>
                <Modal.Title className="modalTitle"><img src={CheckIcon} alt="check icon" style={{width:"50px",marginBottom:"0px",marginRight:"12px"}} /> You Did It!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{textAlign:"center"}}>Congratulations on a successful launch of Elevate.  Let’s keep the momentum going with lots of great support and training for you and your agents.  Cheers to you!</div>
                </Modal.Body>
            </Modal>
        </Row>
        )
    }
}



export default Calendar6Step
