import React from 'react';
import PropTypes from 'prop-types';

const Step = ({ type = 'checkbox', name, checked = false, onChange  }) => (
  <input type={type} name={name} checked={checked} onChange={onChange}  />
);

Step.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

export default Step;